import { Injectable, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  setAsideLeft,
  setAsideRight,
  setOverlaySearcher,
  setOverlayUploader,
  toggleAsideLeft,
  toggleAsideRight,
} from '@libs/store/settings/actions';
import { selectResources } from '@libs/store/resources/selectors';
import {
  selectAsideLeft,
  selectAsideRight,
  selectOverlaySearcher,
  selectOverlayUploader,
} from '@libs/store/settings/selectors';
import { setSearcherShow } from '@libs/store/searcher/actions';

@Injectable({
  providedIn: 'root',
})
export class StoreSettingService {
  constructor(private store: Store) {}

  /**
   * Toggles the right aside panel.
   */
  toggleAsideRight() {
    this.store.dispatch(toggleAsideRight());
  }

  /**
   * Toggles the left aside panel.
   */
  toggleAsideLeft() {
    this.store.dispatch(toggleAsideLeft());
  }

  /**
   * Selects the resources from the store.
   * @returns An observable of the resources.
   */
  selectResources() {
    return this.store.select(selectResources);
  }

  /**
   * Selects the left aside panel state from the store.
   * @returns An observable of the left aside panel state.
   */
  selectAsideLeft() {
    return this.store.select(selectAsideLeft);
  }

  /**
   * Selects the right aside panel state from the store.
   * @returns An observable of the right aside panel state.
   */
  selectAsideRight() {
    return this.store.select(selectAsideRight);
  }

  /**
   * Sets the left aside panel state.
   * @param value - The new state of the left aside panel.
   */
  setAsideLeft(value: boolean) {
    this.store.dispatch(setAsideLeft({ value }));
  }

  /**
   * Sets the right aside panel state.
   * @param value - The new state of the right aside panel.
   */
  setAsideRight(value: boolean) {
    this.store.dispatch(setAsideRight({ value }));
  }

  /**
   * Sets the overlay uploader state.
   * @param overlayUploader - The new state of the overlay uploader.
   */
  setOverlayUploader(overlayUploader: boolean) {
    this.store.dispatch(setOverlayUploader({ overlayUploader }));
  }

  /**
   * Sets the searcher show state.
   * @param show - The new state of the searcher show.
   */
  setSearcherShow(show: boolean) {
    this.store.dispatch(setSearcherShow({ show }));
  }

  /**
   * Selects the overlay searcher state from the store.
   * @returns An observable of the overlay searcher state.
   */
  selectOverlaySearcher() {
    return this.store.select(selectOverlaySearcher);
  }

  /**
   * Sets the overlay searcher state.
   * @param overlaySearcher - The new state of the overlay searcher.
   */
  setOverlaySearcher(overlaySearcher: boolean) {
    this.store.dispatch(setOverlaySearcher({ overlaySearcher }));
  }

  /**
   * Selects the overlay uploader state from the store.
   * @returns An observable of the overlay uploader state.
   */
  selectOverlayUploader() {
    return this.store.select(selectOverlayUploader);
  }
}
